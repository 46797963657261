<!-- 导入报名数据 -->
<template>
    <div class="Registration">
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="100px">
            <el-form-item label="查询日期">
                <el-date-picker v-model="form.queryDate" type="daterange" 
                value-format="yyyy-MM-dd" 
                range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="人员姓名">
                <el-input v-model="form.fullName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <!-- el-form-item label="考试大类">
                <el-select v-model="form.exam" filterable placeholder="请选择考试大类" :clearable="true">
                    <el-option v-for="item in form.examOpt" :key="item.id" :label="item.exam_name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="选择机构">
                <el-select v-model="form.school" filterable placeholder="请输入并选择机构" :clearable="true">
                    <el-option
                        v-for="item in form.schoolOpt"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="" style="margin-left: 22px;margin-top: 5px;margin-bottom: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="Query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="empty">
                    重置
                </el-button>
            </div>
        </el-form>
        <!-- 按钮功能部分 -->
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <div class="top">
                    <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                        @click="refresh">
                        <!-- 刷新 -->
                    </el-button>
                    <el-button slot="trigger" size="small" class="drawInto" @click="open">
                        导入数据
                    </el-button>
                    
                    <!-- 选择机构 -->
                    <el-dialog title="选择机构与考试大类" width="667px" :visible.sync="dialog">
                        <el-divider></el-divider>
                        <el-form :label-position="labelPosition" :rules="rules" ref="ruleForm" class="form-1"  
                        label-width="120px" :model="ruleForm">
                            <el-form-item label="选择机构" prop="mechanism">
                                <el-select v-model="ruleForm.mechanism" filterable placeholder="请输入并选择机构" 
                                :clearable="true" @change="mechanismChange">
                                   <el-option v-for="item in ruleForm.mechanismChose" :key="item.id"
                                        :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="考试大类" prop="bigClass">
                                <el-select v-model="ruleForm.bigClass" filterable placeholder="请输入并选择考试大类" 
                                :clearable="true" @change="bigClassChange">
                                   <el-option v-for="item in ruleForm.bigClassChose" :key="item.id" :label="item.exam_name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                        <el-divider></el-divider>
                        
                        <div class="footer">
                            <!-- 导入 -->
                            <el-upload ref="upload" 
                                :limit="1" :before-upload="beforeUpload" accept=".xls,.xlsx" class="up-class"
                                action="https://artxt.szart.cn/api/public/index.php/api/mechanismexam/upload2"
                                :data ="record" 
                                :on-success="uploadSuccess" :show-file-list="false" :auto-upload="true" :disabled="flag">
                                <el-button style="background-color: #18BC9C;color: #FFFFFF;"
                                @click="submitForm('ruleForm')"> 提 交 </el-button>
                            </el-upload>
                        	
                        	<el-button style="background-color: #f5f5f5;color: #666;margin-left: 10px;"
                             @click="resetForm('ruleForm')">
                                取 消
                            </el-button>
                        </div>    
                    </el-dialog>
                    
                    <!-- 弹出提示 -->
                    <el-dialog title="提示" :visible.sync="dlog" width="40%" :before-close="handleClose">
                        <span>正在导入数据中，请稍等。。。</span>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="dlog = false">取 消</el-button>
                            <el-button style="background:#409EFF ;color: #fff;" @click="dlog = false">确 定</el-button>
                        </span>
                    </el-dialog>    
                    
                    
                </div>
                <button class="surface" v-for="(item,index) in tabs" :key="index" @click="toTab(index)"
                    :class="tab==index?'active':''">
                    {{item}}
                </button>
            </div>
            
        </div>
        <!-- 考生报名表 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;"
         height="650" v-if="tab==0" :key="0" @sort-change="abc">
            <el-table-column prop="name" sortable="custom"   label="姓名" align="center" width="100">
            </el-table-column>
            <el-table-column prop="english_name" sortable="custom" label="拼音名" align="center" width="200">
            </el-table-column>
            <el-table-column prop="sex" label="性别" sortable="custom" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.sex==1">男</span>
                    <span v-else-if="scope.row.sex==2">女</span>
                </template>
            </el-table-column>
            <el-table-column prop="birthday" sortable="custom" label="出生日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="nation2" sortable="custom" label="国籍" align="center" width="120">
            </el-table-column>
            <el-table-column prop="nation" sortable="custom" label="民族" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course" sortable="custom" label="科目" align="center" width="150">
            </el-table-column>
            <el-table-column prop="ylevel" sortable="custom" label="原等级" align="center" width="100">
            </el-table-column>
            <el-table-column prop="blevel" sortable="custom" label="报考等级" align="center" width="150">
            </el-table-column>
            <el-table-column prop="phome"  sortable="custom" label="移动电话" align="center" width="150"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="idcard" sortable="custom" label="证件号" align="center" width="200">
            </el-table-column>
            <!-- <el-table-column prop="is_certificate" sortable="custom" label="是否有副证" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_certificate==1">是</span>
                    <span v-else-if="scope.row.is_certificate==2">否</span>
                </template>
            </el-table-column> -->
            <el-table-column prop="org_name" sortable="custom" label="导入机构" align="center" width="150">
            </el-table-column>
            <el-table-column prop="add_time" sortable="custom" label="操作时间" align="center" width="302">
            </el-table-column>
        </el-table>

        <!-- 导入表 -->
        <el-table :data="importData" border stripe style="width:97.9%;flex: 1;" height="650"
         v-if="tab==1" :key="1" @sort-change="abc">
            <el-table-column  label="序号" type="index"  align="center" width="80">
            </el-table-column>
            <el-table-column prop="name"  sortable="custom"  label="姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="english_name" sortable="custom" label="拼音名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="sex" label="性别" sortable="custom" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.sex==1">男</span>
                    <span v-else-if="scope.row.sex==2">女</span>
                </template>
            </el-table-column>
            <el-table-column prop="birthday" sortable="custom" label="出生日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="nation2" sortable="custom" label="国籍" align="center" width="120">
            </el-table-column>
            <el-table-column prop="nation" sortable="custom" label="民族" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course" sortable="custom" label="科目" align="center" width="120">
            </el-table-column>
            <el-table-column prop="ylevel" sortable="custom" label="等级" align="center" width="150">
            </el-table-column>
            <el-table-column prop="phome" sortable="custom" label="移动电话" align="center" width="150" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="idcard" sortable="custom" label="证件号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="is_certificate" sortable="custom" label="是否有副证" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_certificate==1">是</span>
                    <span v-else-if="scope.row.is_certificate==2">否</span>
                </template>
            </el-table-column>
            <el-table-column prop="org_name" sortable="custom" label="导入机构" align="center" width="150">
            </el-table-column>
            <el-table-column label="操作" align="center" width="120" fixed="right">
                <template slot-scope="scope">
                    <el-button class="edit" icon="el-icon-edit" size="mini" @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50, 100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    queryDate:'', //查询日期
                    fullName: "", //人员姓名
                    school: "", //学校
                    schoolOpt:[],//学校选项
                    exam:'',//考试大类
                    examOpt:[],//考试大类选项
                },
                tabs: ["考生报名表", "导入表"],
                tableData: [], //考生报名表数据
                importData: [], //导入表数据
                currentPage: 1,
                currentLimit: 20,
                total: 0,
                tab: 0, //代表下标
                labelPosition: 'right',
                dialog: false,//判断是否弹窗
                ruleForm:{
                    mechanism:'',//机构
                    mechanismChose:[],//机构选项
                    bigClass:"",
                    bigClassChose:[],//考试大类选项
                },
                rules:{
                    mechanism: [
                         { required: true, message: '请选择机构', trigger: 'change' }
                    ],
                    bigClass: [
                         { required: true, message: '请选择考试大类', trigger: 'change' }
                    ],
                },
                record:{
                    uid:"",
                    bigId:""
                },
                bool:false,
                flag:false,//判断是否禁用
                dlog:false,//提示
                arr:[]
            }
        },
        created() {
            // 选择机构
            this.$request({
                url: "/api/mechanism/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log("选择机构",res.data.list)
                this.form.schoolOpt = res.data.list
                this.ruleForm.mechanismChose = res.data.list
            })
            //考试大类
            this.$request({
                url: "/api/ExamCategory/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log("考试大类",res.data.list)
                this.form.examOpt = res.data.list
                this.ruleForm.bigClassChose = res.data.list
            })
            this.getList()
        },
        activated() {
           this.getList()
        },
        methods: {
            getList(){
                if(this.tab==0){
                    this.$request({url: '/api/Examsignup/list', method: 'POST', data:{
                        page: this.currentPage,
                        limit: this.currentLimit,
                        sel_date:this.form.queryDate,//查询日期
                        name:this.form.fullName,
                        org_id:this.form.school,
                        sort:this.arr
                        // apply_category:this.form.exam//考试大类id
                    }}).then(res=>{
                        console.log(res.data.list)
                        this.tableData=res.data.list
                        this.total=res.data.count
                    })
                }else if(this.tab==1){
                    this.$request({url: '/api/Examsignup/list', method: 'POST', data:{
                        page: this.currentPage,
                        limit: this.currentLimit,
                        sel_date:this.form.queryDate,//查询日期
                        name:this.form.fullName,
                        org_id:this.form.school,
                        sort:this.arr
                        // apply_category:this.form.exam//考试大类id
                    }}).then(res=>{
                        // console.log(res)
                        this.importData=res.data.list
                        this.total=res.data.count
                    })
                }
            },
            handleSizeChange(val) {//条数
                this.currentLimit = val
                this.getList()
            },
            handleCurrentChange(val) {//页数
                this.currentPage = val
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            toTab(index) {
                this.tab = index
                this.getList()
            },
            edit(index,row) { //编辑
                this.$router.push({
                    path: "editImportTable",
                    query: {
                        id:row.id
                    }
                })
            },
            Query(){
                this.currentPage=1
                this.getList()
                // console.log(this.form.queryDate)
            },
            empty(){//重置
                this.form.queryDate=""//查询日期
                this.form.fullName= ""//人员姓名
                this.form.school= ""//学校
                this.form.exam=''//考试大类id
            },
            mechanismChange(e){
                // console.log(e)
                let result = this.ruleForm.mechanismChose.find(item => item.id == e)
                // console.log(result.id)
                this.record.uid=result.id
            },
            bigClassChange(e){
                let result = this.ruleForm.bigClassChose.find(item => item.id == e)
                console.log(result.id)
                this.record.bigId=result.id
            },
            // 弹窗
            open(){
                this.dialog=true
            },
            submitForm(ruleForm) {//提交导入
                this.$refs[ruleForm].validate((valid) => {
                    if (valid) {
                        // console.log(this.ruleForm)
                        // if(this.bool){
                        //     return
                        // }
                        // this.bool=true
                        this.dialog=false
                        this.flag=false
                        
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(ruleForm) {//取消
                this.dialog=false
                this.$refs[ruleForm].resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            // 导入
            beforeUpload(file) {
                let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
                console.log(extension)
                const isXLS = extension === 'xls';
                const isXLSX = extension === 'xlsx';
                let flag = [isXLS, isXLSX].includes(true)
                if (!flag) {
                    this.$message({
                        message: '上传文件只能是 xls，xlsx格式!',
                        type: 'warning'
                    });
                    return false;
                }
                this.dlog = true
            },
            uploadSuccess(res, file, fileList) {
                this.dlog = false
                if (res.code == 200) {
                    this.$message({
                        message: '导入成功!',
                        type: 'success'
                    });
                } else {
                    this.$alert(res.msg, '导入失败', {
                        confirmButtonText: '确定',
                    });
                }
                this.$refs.upload.clearFiles();
            },
            
            // 姓名排序
            fn2(a, b){
                return a.name.localeCompare(b.name)
            },
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[] //考生报名表数据
                this.importData= []
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }
    }
</script>

<style scoped="scoped">
    .Registration {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 查询部分 */
    .form {
        padding-top: 34px;
    }

    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    .form {
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
    }
    
    .top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    /* 弹窗 */
    .form-1{
       padding: 10px !important;
       width: 500px !important;
       margin: 0px auto !important; 
       margin-top: 5px !important;
    }
    .form-1::v-deep .el-input__inner {
        width: 300px;
        height: 37px;
    }
    
    .form-1 ::v-deep .el-form-item__label {
        color: #000000;
    }
    ::v-deep .el-dialog__body{
        padding: 25px 25px 30px !important;
    }
    ::v-deep .el-dialog__footer{
        padding:10px 20px 20px !important;
        text-align: center;
    }
    .footer{
        display: flex;
        justify-content: center;
        padding-bottom: 20px; 
        
    }
    .dialog-footer{
        text-align: center;
    }
    ::v-deep .el-dialog__title{
        font-weight: bold;
    }

    /* 导入数据按钮 */
    .drawInto {
        display: inline-block;
        width: 100px;
        height: 30px;
        padding-left: 20px;
        vertical-align: middle;
        background: url(../../assets/import.png) 10px 7px no-repeat #FF7300;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        margin: 0px 10px;
        cursor: pointer;
    }

    .surface {
        display: inline-block;
        padding: 6px 14px;
        vertical-align: middle;
        margin-right: 14px;
        color: #333333;
        border: 1px solid #999999;
        background-color: #FFFFFF;
        border-radius: 3px;
        cursor: pointer;
    }

    /* 选中样式 */
    .active {
        background: #CDEEEB;
    }

    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }

    .edit {
        color: #FFFFFF;
        background: #18BC9C;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
