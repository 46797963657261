import { render, staticRenderFns } from "./RegistrationData.vue?vue&type=template&id=540c3bac&scoped=true&"
import script from "./RegistrationData.vue?vue&type=script&lang=js&"
export * from "./RegistrationData.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationData.vue?vue&type=style&index=0&id=540c3bac&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "540c3bac",
  null
  
)

export default component.exports